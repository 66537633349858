import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '首页',
      keywords: 'AI，AI视频管理，智能视频分析，计算机视觉，人工智能，机器学习，智能视频监控系统，OCR',
      description: '为了进一步提升客户服务体验，壹合原码倾力打造了一系列全方位的支持资源，我们精心准备了《AI视频监控平台单机版安装使用手册》，旨在以详尽的步骤指导每一位用户轻松上手。不仅如此，还特别制作了人脸识别部署与边缘盒子部署的视频教程帮助用户熟悉平台的操作、部署方法。考虑到实际应用场景中的各种需求，壹合原码还提供了边缘盒子IP查询工具，帮助用户快速定位设备信息。同时，为了提供个性化服务，壹合原码向所有用户提供平台告警语音的音频模板，用户可以定制告警语音。值得一提的是，为了更好地帮助平台用户，壹合原码专门定制了平台的教程视频，助力用户迅速掌握核心技能，推动项目高效落地。'
    }
  },
  {
    path: '/software',
    name: 'softwareView',
    component: () => import('../views/newSoftware.vue'),
    meta: {
      title: '软件平台',
      keywords: 'AI，AI视频管理，智能视频分析，计算机视觉，人工智能，机器学习，智能视频监控系统，OCR',
      description: '1.壹合原码AI视频监控平台，现提供三种灵活多样的部署方案， 以满足不同 场景下的用户需求：AIBOX集群版：专为大规模监控网络设计，能够高效管理与协调多个边缘计算盒子，实现资源的统一调度与优化配置，特别适用于需要处理海量视频数据的大型项目。SingleBox单机版：适用于小型或独立监控场景，平台可直接部署于单一边缘计算设备上，简化了安装与维护流程，确保了数据处理的即时性与安全性。Master服务器版：面向广域网环境，支持远程服务器部署，不仅便于跨地域的集中管理，还提供了更高的数据存储与处理能力，适合于多点联动、跨区域监控的应用场景。通过这三种版本的选择，壹合原码AI视频监控平台能够灵活应对从单点监控到大规模集群管理的各种需求，确保用户在不同环境下都能获得最佳的监控效果与使用体验。'
    }
  },
  {
    path: '/software/detail',
    name: 'softwareDetail',
    component: () => import('../views/components/softwareDetail.vue'),
    meta: {
      title: '首页',
      keywords: 'AI，AI视频管理，智能视频分析，计算机视觉，人工智能，机器学习，智能视频监控系统，OCR',
      description: '为了进一步提升客户服务体验，壹合原码倾力打造了一系列全方位的支持资源，我们精心准备了《AI视频监控平台单机版安装使用手册》，旨在以详尽的步骤指导每一位用户轻松上手。不仅如此，还特别制作了人脸识别部署与边缘盒子部署的视频教程帮助用户熟悉平台的操作、部署方法。考虑到实际应用场景中的各种需求，壹合原码还提供了边缘盒子IP查询工具，帮助用户快速定位设备信息。同时，为了提供个性化服务，壹合原码向所有用户提供平台告警语音的音频模板，用户可以定制告警语音。值得一提的是，为了更好地帮助平台用户，壹合原码专门定制了平台的教程视频，助力用户迅速掌握核心技能，推动项目高效落地。'
    }
  },
  {
    path: '/mall',
    name: 'mall',
    component: () => import('../views/mall.vue'),
    meta: {
      title: '首页',
      keywords: 'AI，AI视频管理，智能视频分析，计算机视觉，人工智能，机器学习，智能视频监控系统，OCR',
      description: '壹合原码AI视频监控平台，始终坚守持续创新与用户至上的核心价值观，匠心独运地构建了一套动态演进的算法库。平台内嵌的前沿算模型，经由专业团队的精心研发与严苛测试，针对各行业特定的监控需求进行了深度优化，确保了监测结果的高度精确与实时反馈。无论是在人流密集的公共场所，还是在高风险的工业生产现场，壹合原码AI视频监控平台都能凭借其卓越的算法性能，为用户提供全方位的安全保障，助力各行业实现智能化管理的飞跃。'
    }
  },
  {
    path: '/mall/detail',
    name: 'mallDetail',
    component: () => import('../views/components/mallDetail.vue'),
    meta: {
      title: '首页',
      keywords: 'AI，AI视频管理，智能视频分析，计算机视觉，人工智能，机器学习，智能视频监控系统，OCR',
      description: '壹合原码AI视频监控平台，始终坚守持续创新与用户至上的核心价值观，匠心独运地构建了一套动态演进的算法库。平台内嵌的前沿算模型，经由专业团队的精心研发与严苛测试，针对各行业特定的监控需求进行了深度优化，确保了监测结果的高度精确与实时反馈。无论是在人流密集的公共场所，还是在高风险的工业生产现场，壹合原码AI视频监控平台都能凭借其卓越的算法性能，为用户提供全方位的安全保障，助力各行业实现智能化管理的飞跃。'
    }
  },
  {
    path: '/inter',
    name: 'inter',
    component: () => import('../views/inter.vue'),
    meta: {
      title: '首页',
      keywords: 'AI，AI视频管理，智能视频分析，计算机视觉，人工智能，机器学习，智能视频监控系统，OCR',
      description: '为积极响应国家政策，全力支持国产芯片的发展，壹合原码的AI视频管理平台，已针对多家中国AI芯片制造商的产品进行了全面适配。壹合原码AI视频监控平台不仅强化了与国产芯片的协同效应，还进一步拓宽了平台的硬件兼容范围，为用户提供更多样化的选择。'
    }
  },
  {
    path: '/inter/hardwareDetail',
    name: 'hardwareDetail',
    component: () => import('../views/components/hardwareDetail.vue'),
    meta: {
      title: '首页',
      keywords: 'AI，AI视频管理，智能视频分析，计算机视觉，人工智能，机器学习，智能视频监控系统，OCR',
      description: '为积极响应国家政策，全力支持国产芯片的发展，壹合原码的AI视频管理平台，已针对多家中国AI芯片制造商的产品进行了全面适配。壹合原码AI视频监控平台不仅强化了与国产芯片的协同效应，还进一步拓宽了平台的硬件兼容范围，为用户提供更多样化的选择。'
    }
  },
  {
    path: '/openSource',
    name: 'openSourceView',
    component: () => import('../views/openSource.vue'),
    meta: {
      title: '首页',
      keywords: 'AI，AI视频管理，智能视频分析，计算机视觉，人工智能，机器学习，智能视频监控系统，OCR',
      description: '为了进一步提升客户服务体验，壹合原码倾力打造了一系列全方位的支持资源，我们精心准备了《AI视频监控平台单机版安装使用手册》，旨在以详尽的步骤指导每一位用户轻松上手。不仅如此，还特别制作了人脸识别部署与边缘盒子部署的视频教程帮助用户熟悉平台的操作、部署方法。考虑到实际应用场景中的各种需求，壹合原码还提供了边缘盒子IP查询工具，帮助用户快速定位设备信息。同时，为了提供个性化服务，壹合原码向所有用户提供平台告警语音的音频模板，用户可以定制告警语音。值得一提的是，为了更好地帮助平台用户，壹合原码专门定制了平台的教程视频，助力用户迅速掌握核心技能，推动项目高效落地。'
    }
  },
  {
    path: '/contactUs',
    name: 'contactUsView',
    component: () => import('../views/contactUs.vue'),
    meta: {
      title: '关于我们',
      keywords: 'AI，AI视频管理，智能视频分析，计算机视觉，人工智能，机器学习，智能视频监控系统，OCR',
      description: '1.壹合原码诚挚邀请每一位对AI视频监控平台充满好奇与热情的用户，加入我们的探索之旅。无论您是AI行业专家，还是对人工智能应用充满憧憬的其他行业人员，我们都热切期待与您展开深度交流。联系我们，我们将全力以赴，为您提供详尽的平台信息，让您对AI视频监控平台有更深入的理解。我们不仅致力于分享前沿的技术动态，更愿意倾听您的需求，共同探讨如何将AI的力量最大化地融入您的业务场景中。在计算机视觉及人工智能领域，我们拥有丰富的经验和独到的见解。选择与我们联系，意味着您将获得专业的一对一咨询服务，从概念理解到具体实施，我们都会提供量身定制的建议和解决方案，助您在智能时代中抢占先机。'
    }
  }
]

//需要设置keepAlive的路由path
// const needKeepAlivePath = [];
// const files = require.context('../views', true, /\.vue$/)
// files.keys().forEach(name => {
//   const path = name.replaceAll('./', '/').replaceAll('.vue', '')
//   routes.push({
//     path: path,
//     name: path,
//     component: () => import(`@/views${name.substr(1)}`),
//     meta: {
//       keepAlive: needKeepAlivePath.includes(path)
//     }
//   })
// })

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  /*********动态修改keywords和description*************/
  if(Object.keys(to.meta).length>0 && to.matched.length>0){
    let this_meta=to.matched[to.matched.length-1].meta
    console.log(this_meta,'---this_meta---')
    if(this_meta.title)document.title=this_meta.title
    let head = document.getElementsByTagName('head');
    let meta_keyword=document.createElement('meta');
    if(document.querySelector('meta[name="keywords"]')){
      document.querySelector('meta[name="keywords"]').setAttribute('content',this_meta.keywords)
    }else{
      meta_keyword.setAttribute('name','keywords')
      meta_keyword.setAttribute('content',this_meta.keywords)
      head[0].appendChild(meta_keyword)
    }
    let meta_description=document.createElement('meta');
    if(document.querySelector('meta[name="description"]')){
      document.querySelector('meta[name="description"]').setAttribute('content',this_meta.description)
    }else{
      meta_description.setAttribute('name','description')
      meta_description.setAttribute('content',this_meta.description)
      head[0].appendChild(meta_description)
    }
  }
  /**********************************************/
  next()
})

export default router
