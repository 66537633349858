<template>
  <div class="home">
    <div>
      <el-carousel :height="carouselHeight">
        <el-carousel-item v-for="item in imgList" :key="item">
          <img ref="imgRef" :src="item" style="width: 100%;object-fit: cover;background-repeat: no-repeat;"/>
        </el-carousel-item>
      </el-carousel>
      <div class="home-cont">
        <div class="title">功能视频</div>
        <el-row :gutter="15">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <video src="https://yihecode-selfweb.oss-cn-beijing.aliyuncs.com/software/tutorial-videos/AI%E8%A7%86%E9%A2%91%E7%AE%A1%E7%90%86%E5%B9%B3%E5%8F%B0%E5%8A%9F%E8%83%BD%E6%95%B4%E4%BD%93%E4%BB%8B%E7%BB%8D.mp4" preload="auto" controls id="myVideo" width="100%"></video>
            <div class="title-mini">整体功能平台介绍</div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <video src="https://yihecode-selfweb.oss-cn-beijing.aliyuncs.com/index/videos/%E7%83%9F%E7%81%AB%E6%95%88%E6%9E%9C%E8%A7%86%E9%A2%91.mp4" preload="auto" controls id="myVideo" width="100%"></video>
            <div class="title-mini">算法识别效果</div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <video src="https://yihecode-selfweb.oss-cn-beijing.aliyuncs.com/index/videos/%E8%AF%AD%E9%9F%B3%E7%94%B5%E8%AF%9D%E6%8E%A8%E9%80%81%E5%8A%9F%E8%83%BD-%E7%83%9F%E7%81%AB.mp4" preload="auto" controls id="myVideo" width="100%"></video>
            <div class="title-mini">语音电话通知</div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <video src="https://yihecode-selfweb.oss-cn-beijing.aliyuncs.com/index/videos/%E5%B9%B3%E5%8F%B0%E8%AF%AD%E9%9F%B3%E9%80%9A%E7%9F%A5.mp4" preload="auto" controls id="myVideo" width="100%"></video>
            <div class="title-mini">平台语音通知</div>
          </el-col>
        </el-row>
        <div class="title">算法识别效果</div>
        <el-row :gutter="15">
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <img src="@/assets/image/home/people.gif" style="width: 100%;" />
            <div class="title-mini">行人检测</div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <img src="@/assets/image/home/car.gif" style="width: 100%;"/>
            <div class="title-mini">车辆检测</div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
            <img src="@/assets/image/home/smoke.gif" style="width: 100%;"/>
            <div class="title-mini">烟火检测</div>
          </el-col>
        </el-row>
        <div class="title">软件平台</div>
        <el-row :gutter="15">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="mb-50">
              <img src="@/assets/image/home/image1.png" style="width: 100%;" />
              <div class="title-mini">Master服务器版</div>
              <div class="tip">英伟达服务器版本，稳定高效，适用使用GPU部署的客户环境。</div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="mb-50">
              <img src="@/assets/image/home/image2.png" style="width: 100%;" />
              <div class="title-mini">AIBox集群版</div>
              <div class="tip">支持多个边缘盒子集中管理调度、识别控制，算法升级、算法下发，告警汇聚等核心管理能力。</div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="mb-50">
            <img src="@/assets/image/home/image3.png" style="width: 100%;" />
            <div class="title-mini">SIngleBox单机版</div>
            <div class="tip">单台边缘盒子，软件一体交付。盒子支持部署操作平台、算法，适用于快速体验，可现场快速部署与落地。</div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="mb-50">
            <img src="@/assets/image/home/image4.png" style="width: 100%;" />
            <div class="title-mini">APP客户端</div>
            <div class="tip">手机 APP,联动Web端平台数据，用户可远程跟踪现场情况，及时查看告警信息,需保证网络互通。</div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data(){
    return{
      imgList:[
        require('@/assets/image/home/bnr1.png'),
        require('@/assets/image/home/bnr2.png'),
        require('@/assets/image/home/bnr3.png'),
      ],
      carouselHeight: '563px'
    }
  },
  created() {
    this.calculateHeight()
    window.addEventListener('resize', function() {
      this.calculateHeight()
    }.bind(this));
  },
  // mounted() {
  //   setTimeout(function () {
  //    this.calculateHeightOfImg();
  //   }.bind(this),0)

  //   window.addEventListener('resize', function() {
  //     this.calculateHeightOfImg()
  //   }.bind(this));
  // },
  // beforeDestroy() {
  //   window.removeEventListener('resize',this.calculateHeightOfImg)
  // },
  methods: {
    calculateHeight() {
      let proportion = 500 / 1500;
      let num = window.innerWidth * proportion-6;
      if(num<106){
        num = 106;
      }
      this.carouselHeight = num + 'px';
    },
    // calculateHeightOfImg() {
    //   this.carouselHeight = this.$refs.imgRef[0].offsetHeight + 'px'
    // }
  }
}
</script>
<style scoped lang="scss">
.home{
  width: 100%;
  padding-top: 72px;
  :deep(.el-carousel__arrow--left) {
    display: none !important;
  }
  :deep(.el-carousel__arrow--right) {
    display: none !important;
  }
  :deep(.el-carousel__indicators){
    button {
      width: 16px;
      height: 16px;
      border-radius: 50%; /* 使指示器变成圆形 */
      background-color: #FFF;
      border: none;
      margin: 5px;
      opacity: unset;
    }
  }
  :deep(.is-active){
    button {
      width: 16px;
      height: 16px;
      border-radius: 50%; /* 使指示器变成圆形 */
      background-color: #FF8056;
      border: none;
      margin: 5px;
    }
  }
  .title{
    color: #030F4B;
    font-weight: bold;
    padding: 48px 0px;
    text-align: center;
    font-size: 28px;
  }
  .title-mini{
    color: #030F4B;
    font-size: 18px;
    text-align: center;
    margin: 16px 0px;
    font-weight: bold;
  }
  .home-cont{
    padding: 0px 10%;
    padding-bottom: 50px;
    background: url('@/assets/image/bj.png')  no-repeat 0px 0px;
    background-size:cover;
  }
  .tip{
    font-size: 14px;
    color: #4A4E63;
    text-align: center;
  }
  .mb-50{
    margin-bottom: 50px;
  }
  @media(max-width:768px){
    :deep(.el-carousel__indicators){
      button {
        width: 10px;
        height: 10px;
        border-radius: 50%; /* 使指示器变成圆形 */
        background-color: #FFF;
        border: none;
        margin: 5px;
        opacity: unset;
      }
    }
    :deep(.is-active){
      button {
        width: 10px;
        height: 10px;
        border-radius: 50%; /* 使指示器变成圆形 */
        background-color: #FF8056;
        border: none;
        margin: 5px;
      }
    }
    :deep(.el-carousel__indicator--horizontal){
      padding: 0px 4px;
    }
    .title{
      padding: 16px 0px;
      font-size: 20px;
    }
    .title-mini{
      margin: 8px 0px;
      font-size: 16px;
    }
    .mb-50{
      margin-bottom: 20px;
    }
  }
}
</style>
